"use client";

import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { usePathname, useRouter } from "next/navigation";
import { DashboardPreclient } from "./dashboard-preclient";
import { Dashboard } from "./dashboard";

export function Home() {
  const jwt = useJwt();
  const activePath = usePathname();
  const router = useRouter();

  if (activePath === "/") {
    if (jwt.segment === Segment.CorporateEntities) {
      router.push("/investing-tools/baird-research");
      return;
    }
  }

  if (jwt.segment === Segment.Preclient) {
    return <DashboardPreclient />;
  }

  return <Dashboard />;
}
