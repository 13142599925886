"use client";

import { type IShapeDrawer, type ISourceOptions } from "@tsparticles/engine";
import Particles, {
  initParticlesEngine,
  type IParticlesProps,
} from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { useEffect, useState } from "react";

const CONFETTI_OPTIONS = {
  fpsLimit: 120,
  detectRetina: true,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: "push",
      },
      onHover: {
        enable: true,
        mode: "repulse",
      },
    },
    modes: {
      push: {
        quantity: 20,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
    },
  },
  particles: {
    color: {
      value: [
        "#E28AD4", // red
        "#FF8A00", // orange
        "#63D868", // green
        "#3CB6CE", // blue
        "#8A8EE2", // purple
      ],
    },
    move: {
      direction: "bottom",
      enable: true,
      outModes: {
        default: "out",
      },
      size: true,
      speed: {
        min: 1,
        max: 5,
      },
    },
    number: {
      value: 500,
      density: {
        enable: true,
        // area: 800,
      },
    },
    opacity: {
      value: 1,
      animation: {
        enable: false,
        startValue: "max",
        destroy: "min",
        speed: 0.3,
        sync: true,
      },
    },
    rotate: {
      value: {
        min: 0,
        max: 360,
      },
      direction: "random",
      move: true,
      animation: {
        enable: true,
        speed: 60,
      },
    },
    tilt: {
      direction: "random",
      enable: true,
      move: true,
      value: {
        min: 0,
        max: 360,
      },
      animation: {
        enable: true,
        speed: 60,
      },
    },
    shape: {
      type: ["baird"],
    },
    size: {
      value: {
        min: 4,
        max: 10,
      },
    },
    roll: {
      darken: {
        enable: true,
        value: 30,
      },
      enlighten: {
        enable: true,
        value: 30,
      },
      enable: true,
      speed: {
        min: 15,
        max: 25,
      },
    },
    wobble: {
      distance: 30,
      enable: true,
      move: true,
      speed: {
        min: -15,
        max: 15,
      },
    },
  },
} satisfies ISourceOptions;

const CONFETTI_SHAPE_BAIRD = {
  validTypes: ["baird"] as const,
  getSidesCount: () => 4,
  draw({ context, radius }) {
    const adj = (n: number) => (radius / 11) * (n - 11);
    context.beginPath();
    context.moveTo(adj(0), adj(8.75));
    context.lineTo(adj(22), adj(0));
    context.lineTo(adj(22), adj(13.25));
    context.lineTo(adj(0), adj(22));
    context.lineTo(adj(0), adj(8.75));
    context.closePath();
    context.fill();
  },
} satisfies IShapeDrawer;

interface ConfettiProps
  extends React.ComponentPropsWithoutRef<"div">,
    Omit<IParticlesProps, "id" | "options"> {}

export function Confetti(props: ConfettiProps) {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      engine.addShape(CONFETTI_SHAPE_BAIRD);
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  if (!init) {
    return null;
  }

  return <Particles {...props} id="confetti" options={CONFETTI_OPTIONS} />;
}
