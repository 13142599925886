import { Date } from "#app/_ui/components/textFormaters/Date";
import { Money } from "#app/_ui/components/textFormaters/Money";
import { Card, CardContent, CardHeader } from "#ui/card";
import { GainLoss } from "#ui/gain-loss";
import { cx } from "#ui/style.utils";
import { Span } from "#ui/typography";
import { type PerformanceVm } from "./conversions";

export interface PortfolioSummaryParams {
  className?: string;
  portfolioData: PerformanceVm | undefined;
  asOfLastBusDay: boolean;
}

export function PortfolioSummary({
  className,
  portfolioData,
  asOfLastBusDay,
}: PortfolioSummaryParams) {
  return (
    <div
      className={cx("divide-y divide-shade-10 text-sm *:py-12px", className)}
    >
      <div className="grid grid-cols-2 items-center gap-10px">
        <strong className="font-semibold">Year to Date</strong>
        <GainLoss
          value={portfolioData?.ytd?.rateOfReturn}
          format="percent"
          className="flex-row-reverse"
        />
      </div>
      {portfolioData && asOfLastBusDay ? (
        <div className="grid grid-cols-2 items-center gap-10px">
          <strong className="font-semibold">Month To Date</strong>
          <GainLoss
            value={portfolioData.mtd?.rateOfReturn}
            format="percent"
            className="flex-row-reverse"
          />
        </div>
      ) : (
        ""
      )}
      <div className="grid grid-cols-2 items-center gap-10px">
        <strong className="font-semibold">1 Year</strong>
        <GainLoss
          value={portfolioData?.oneYear?.rateOfReturn}
          format="percent"
          className="flex-row-reverse"
        />
      </div>
      <div className="grid grid-cols-2 items-center gap-10px">
        <strong className="font-semibold">3 Year</strong>
        <GainLoss
          value={portfolioData?.threeYear?.rateOfReturn}
          format="percent"
          className="flex-row-reverse"
        />
      </div>
      <div className="grid grid-cols-2 items-center gap-10px">
        <strong className="font-semibold">5 Year</strong>
        <GainLoss
          value={portfolioData?.fiveYear?.rateOfReturn}
          format="percent"
          className="flex-row-reverse"
        />
      </div>
      <div className="grid grid-cols-2 items-center gap-10px">
        <strong className="font-semibold">% Since Start Date</strong>
        <GainLoss
          value={portfolioData?.sinceStart?.rateOfReturn}
          format="percent"
          className="flex-row-reverse"
        />
      </div>
      <div className="grid grid-cols-2 items-center gap-10px">
        <strong className="font-semibold">Performance Start Date</strong>
        <span className="text-right">
          <Date value={portfolioData?.startDate} />
        </span>
      </div>
    </div>
  );
}

export default function PortfolioSummaryCard({
  portfolioData,
  asOfLastBusDay,
}: PortfolioSummaryParams) {
  return (
    <>
      <Card className="pt-10px lg:col-span-4">
        <CardHeader>
          <div className="space-y-5px text-center">
            <div className="space-y-10px">
              <Span
                size="sm"
                palette="neutral-subtle"
                className="block leading-none"
              >
                {asOfLastBusDay
                  ? "Last Business Day Account Value"
                  : "Month End Account Value"}
              </Span>{" "}
              <Span size="h2" className="block leading-none">
                <Money value={portfolioData?.value} />
              </Span>
            </div>
            <div className="flex items-center justify-center gap-5px text-sm leading-none text-shade-70">
              <span className="sr-only">Decreased by</span>{" "}
              <GainLoss
                value={portfolioData?.ytd?.rateOfReturn}
                format="percent"
              />{" "}
              <abbr title="Year to Date" className="no-underline">
                YTD
              </abbr>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <PortfolioSummary
            asOfLastBusDay={asOfLastBusDay}
            portfolioData={portfolioData}
          />
        </CardContent>
      </Card>
    </>
  );
}
