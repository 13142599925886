"use client";

import { Button, type ButtonProps, type ButtonRef } from "#ui/button";
import {
  Dialog,
  DialogClose,
  DialogCloseIconButton,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogMain,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  type DialogProps,
} from "#ui/dialog";
import { Icon } from "#ui/icon";
import { AnchorButton } from "#ui/link";
import { cx } from "#ui/style.utils";
import { forwardRef, useState } from "react";
import { type UseCreatePdfParams, type UseCreatePdfReturn } from "./pdf-utils";
import { PdfViewer } from "./PdfViewer";

interface PdfViewerDialogProps extends UseCreatePdfParams {
  onOpenChange?: DialogProps["onOpenChange"];
  triggerElement?: React.ReactNode;
}

export function PdfViewerDialog({
  fileName,
  onOpenChange,
  queryFn,
  triggerElement,
}: PdfViewerDialogProps) {
  const [result, setResult] = useState<UseCreatePdfReturn>({
    status: "pending",
    data: null,
    error: null,
  });

  return (
    <Dialog onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        {triggerElement ?? <PdfViewerDialogTrigger />}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          variant="center-scroll-inner"
          aria-describedby={undefined}
        >
          <DialogHeader className="border-b-0">
            <DialogTitle>
              {result.status === "pending"
                ? "Retrieving PDF..."
                : result.status === "error"
                  ? result.error
                  : result.data.name}
            </DialogTitle>
          </DialogHeader>
          <DialogMain className="p-0">
            <PdfViewer
              fileName={fileName}
              onValueChange={setResult}
              queryFn={queryFn}
            />
            <DialogCloseIconButton />
          </DialogMain>
          <DialogFooter className="border-t-0">
            {result.status === "success" ? (
              <AnchorButton
                href={result.data.url}
                download={result.data.name.replaceAll(" ", "-")}
                target="_blank"
                variant="ghost"
                className="mx-auto @lg:ml-0"
              >
                <Icon name="ms-download-filled" />
                <span>Download PDF</span>
              </AnchorButton>
            ) : null}
            <DialogClose asChild>
              <Button variant="outline">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}

export const PdfViewerDialogTrigger = forwardRef<
  ButtonRef,
  Omit<ButtonProps, "children" | "size" | "variant">
>(({ className, ...props }, ref) => (
  <Button
    {...props}
    size="sm"
    variant="link"
    className={cx("gap-3px text-[length:inherit]", className)}
    ref={ref}
  >
    <Icon name="ms-file" />
    <span>
      <span className="sr-only">View as </span>PDF
    </span>
  </Button>
));
PdfViewerDialogTrigger.displayName = "PdfViewerDialogTrigger";
