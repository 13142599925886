"use client";

import {
  type AccountGroup,
  useAccountGroupsQueryOptions,
} from "#app/_api/user-profile-service";
import { AccountInfo } from "#app/_ui/components/accounts/AccountInfo";
import {
  Optgroup,
  Option,
  Select,
  type SelectProps,
  type SelectRef,
} from "#ui/select";
import { useQuery } from "@tanstack/react-query";
import { forwardRef } from "react";

// -----------------------------------------------------------------------------

const SEPARATOR = ",";

function formatValueIn(selectable: string[], selected: string[]) {
  const selectableStr = selectable.join(SEPARATOR);
  const selectedStr = selected.join(SEPARATOR);
  const isNotSelected = selected.length === 0;
  const isAllSelected = selectableStr === selectedStr;
  return isNotSelected || isAllSelected ? selectableStr : selectedStr;
}

function formatValueOut(selectable: string[], selectedStr: string) {
  const selectableStr = selectable.join(SEPARATOR);
  const selectedNext = selectedStr.split(SEPARATOR);
  const selectedNextDeduped = [...new Set(selectedNext)];
  const isAllSelected = selectableStr === selectedStr;
  return isAllSelected ? selectable : selectedNextDeduped;
}

// -----------------------------------------------------------------------------

export type SelectAccountsRef = SelectRef;

export interface SelectAccountsProps
  extends Omit<SelectProps, "children" | "onValueChange" | "value"> {
  onValueChange: (valueChanged: string[]) => void;
  accountsSelectable: string[];
  accountsSelected: string[];
}

const SelectAccounts = forwardRef<SelectAccountsRef, SelectAccountsProps>(
  ({ onValueChange, accountsSelectable, accountsSelected, ...props }, ref) => {
    return (
      <Select
        {...props}
        value={formatValueIn(accountsSelectable, accountsSelected)}
        onValueChange={(accountsSelectedNextStr) =>
          onValueChange(
            formatValueOut(accountsSelectable, accountsSelectedNextStr),
          )
        }
        ref={ref}
      >
        {accountsSelectable.length > 1 ? (
          <Option value={accountsSelectable.join(SEPARATOR)}>
            All Accounts
          </Option>
        ) : null}
        {accountsSelectable.map((el) => (
          <Option value={el} key={el}>
            <AccountInfo accountNumber={el} />
          </Option>
        ))}
        <SelectAccountsGroups />
      </Select>
    );
  },
);

SelectAccounts.displayName = "SelectAccounts";

export { SelectAccounts };

// -----------------------------------------------------------------------------

export const GROUP_VALUE_PREFIX = "groupId";

export function formatGroupValue(group: AccountGroup) {
  return [[GROUP_VALUE_PREFIX, group.id].join(""), ...group.accounts].join(
    SEPARATOR,
  );
}

export function buildAccountGroupStringFromId(
  groupId: string,
  accountGroup: AccountGroup[],
) {
  const options =
    accountGroup.filter((el) => !!el.accounts && el.accounts.length > 0) ?? [];
  const grpAccounts = options.find((grp) => grp.id === groupId);
  const actGrpStr = [formatGroupValue(grpAccounts!)];
  return actGrpStr;
}

function SelectAccountsGroups() {
  const result = useQuery(useAccountGroupsQueryOptions());

  const options =
    result.data?.filter((el) => !!el.accounts && el.accounts.length > 0) ?? [];

  if (options.length === 0) {
    return null;
  }

  return (
    <Optgroup label="Groups">
      {options.map((el) => (
        <Option value={formatGroupValue(el)} key={el.id}>
          {el.name}
        </Option>
      ))}
    </Optgroup>
  );
}
