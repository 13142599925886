"use client";

import { allQueryKeys } from "#app/_api/allQueryKeys";
import * as userProfileService from "#app/_api/user-profile-service";
import { logError } from "#app/lib/logger";
import { Icon } from "#ui/icon";
import { Spinner } from "#ui/spinner";
import { cx } from "#ui/style.utils";
import { H1, P, type H1Props } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import { format, isValid, parseISO } from "date-fns";

export function BannerContent({
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"div">, "children">) {
  return (
    <div {...props} className={cx("space-y-30px", className)}>
      <BannerHeading />
      <BannerDivider />
      <BannerKpis className="pt-10px" />
    </div>
  );
}

export function BannerContentPreclient({
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"div">, "children">) {
  return (
    <div {...props} className={cx("space-y-18px", className)}>
      <BannerHeadingPreclient />
      <P className="opacity-60">Welcome to your Baird Online portal</P>
    </div>
  );
}

function BannerHeading(props: Omit<H1Props, "children" | "size">) {
  const { data } = userProfileService.useFriendlyNameQuery();
  const hasFriendlyName = typeof data === "string" && data.length > 0;
  return (
    <H1 {...props} size="h2" className="leading-none">
      {hasFriendlyName ? `Welcome, ${data}` : "Welcome"}
    </H1>
  );
}

function BannerHeadingPreclient(props: Omit<H1Props, "children" | "size">) {
  const { data } = userProfileService.useFriendlyNameQuery();
  const hasFriendlyName = typeof data === "string" && data.length > 0;
  return (
    <H1 {...props} size="h2" className="leading-none">
      {hasFriendlyName ? `Hi, ${data}` : "Hi"}
    </H1>
  );
}

function BannerDivider({
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"div">, "children">) {
  return <div {...props} className={cx("h-1px bg-white/20", className)} />;
}

function BannerKpis({
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"div">, "children">) {
  const { data, error, isError, isPending, isSuccess } = useQuery(
    allQueryKeys.pwmPortfolioService.portfolio(),
  );

  if (isPending) {
    return (
      <div {...props} className={className}>
        <Spinner palette="white" />
      </div>
    );
  }

  if (isError) {
    logError(error);
  }

  const portfolioValue =
    isSuccess && data?.totals?.value != null
      ? formatToCurrency(data.totals.value)
      : "N/A";
  const portfolioValueDate =
    isSuccess && data?.totals?.asOfDate != null
      ? formatToDateStr(data.totals.asOfDate)
      : "N/A";
  const sumOfDailyChange =
    isSuccess && data?.totals?.change != null
      ? formatToCurrency(data.totals.change)
      : "N/A";
  const sumOfDailyChangePercent =
    isSuccess && data?.totals?.percentChange != null
      ? formatToPercent(data.totals.percentChange)
      : "N/A";
  const cashEquivalents =
    isSuccess && data?.totals?.balances?.cashValue != null
      ? formatToCurrency(data.totals.balances.cashValue)
      : "N/A";

  return (
    <div {...props} className={cx("grid gap-30px md:grid-cols-3", className)}>
      <Kpi>
        <KpiContentSecondary>Portfolio Value</KpiContentSecondary>
        <KpiContentPrimary>{portfolioValue}</KpiContentPrimary>
        <KpiContentSecondary>{portfolioValueDate}</KpiContentSecondary>
      </Kpi>
      <Kpi>
        <KpiContentSecondary>Sum of Daily Change</KpiContentSecondary>
        <KpiContentPrimary>
          <span className="flex items-baseline gap-3px">
            {sumOfDailyChange}
            {sumOfDailyChangePercent.startsWith("+") ? (
              <Icon
                name="ms-arrow-upward"
                size="lg"
                className="translate-y-4px"
              />
            ) : sumOfDailyChangePercent.startsWith("-") ? (
              <Icon
                name="ms-arrow-downward"
                size="lg"
                className="translate-y-3px"
              />
            ) : null}
          </span>
        </KpiContentPrimary>
        <KpiContentSecondary>{sumOfDailyChangePercent}</KpiContentSecondary>
      </Kpi>
      <Kpi>
        <KpiContentSecondary>Cash and Cash Equivalents</KpiContentSecondary>
        <KpiContentPrimary>{cashEquivalents}</KpiContentPrimary>
      </Kpi>
    </div>
  );
}

function Kpi({ className, ...props }: React.ComponentPropsWithoutRef<"div">) {
  return <div {...props} className={cx("space-y-10px", className)} />;
}

function KpiContentPrimary({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div
      {...props}
      className={cx("text-h2 leading-none text-white", className)}
    />
  );
}

function KpiContentSecondary({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div
      {...props}
      className={cx("text-sm leading-none text-white/75", className)}
    />
  );
}

// Stablize constructor for performance
const CurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

// Stablize constructor for performance
const PercentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 2,
  signDisplay: "always",
});

function formatToCurrency(value: number) {
  return CurrencyFormatter.format(value);
}

function formatToPercent(value: number) {
  return PercentFormatter.format(value / 100);
}

function formatToDateStr(value: string) {
  const date = parseISO(value);
  if (!isValid(date)) return "N/A";
  return `As of ${format(date, "M/d/yyyy")}`;
}
