"use client";

import * as userProfileService from "#app/_api/user-profile-service";
import { logError } from "#app/lib/logger";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  type CardRootProps,
} from "#ui/card";
import { Icon } from "#ui/icon";
import { AnchorButton, LinkButton } from "#ui/link";
import { Logo } from "#ui/logo";
import { H2 } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import { PendingCardContent } from "#app/_ui/components/cards/PendingCard";
import ErrorBanner from "#app/_ui/components/errors/error-banner";
import { useNavRoutes } from "#app/_ui/components/headers/use-nav-routes";

export function CardMyTeam(props: CardRootProps) {
  const routes = useNavRoutes();

  const { data, error, isError, isPending, isSuccess } = useQuery(
    userProfileService.useFaInfoQueryOptions(),
  );

  if (isError) {
    logError(error);
  }

  return (
    <Card {...props}>
      <CardHeader palette="gray">
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-wealth-strategy-webinar" size="baird-md" />
          <span>My Team</span>
        </H2>
      </CardHeader>
      <CardContent>
        {isPending ? <PendingCardContent className="md:min-h-fit" /> : null}

        {isError ? <ErrorBanner error="An error occurred." /> : null}

        {isSuccess ? (
          <div className="space-y-20px">
            <div className="flex gap-12px">
              <a
                href={data.branchUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="grid size-60px shrink-0 place-content-center rounded-full border border-shade-15">
                  <Logo size="xs" layout="mark-only" className="size-36px" />
                </div>
              </a>
              <div className="flex-1 space-y-6px">
                <a
                  href={data.branchUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="break-all text-sm font-semibold leading-tight">
                    {data.branchDisplayName}
                  </h3>
                </a>
                <div className="text-sm leading-tight">{data.branchPhone}</div>
                {data.branchUrl ? (
                  <div>
                    <AnchorButton
                      href={data.branchUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="link"
                      className="block break-all border-none text-sm leading-tight"
                    >
                      {data.branchUrl}
                    </AnchorButton>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-wrap items-center gap-10px">
              {/* TODO: "Schedule Appointment" in future story */}
              {/* <LinkButton
                href=""
                size="sm"
                variant="outline"
                palette="neutral"
                className="whitespace-nowrap"
              >
                <Icon name="ms-event-filled" />
                <span>Schedule Appointment</span>
              </LinkButton> */}
              <LinkButton
                href={routes.DocumentsReceived.path}
                size="sm"
                variant="outline"
                palette="neutral"
                className="whitespace-nowrap"
              >
                <Icon name="ms-upload-filled" />
                <span>Upload document</span>
              </LinkButton>
            </div>
          </div>
        ) : null}
      </CardContent>
      <CardFooter palette="white" className="py-12px">
        <LinkButton className="w-full" href={routes.MyTeam.path!}>
          See all
        </LinkButton>
      </CardFooter>
    </Card>
  );
}
