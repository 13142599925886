import { type CardRootProps, Card, CardContent, CardHeader } from "#ui/card";
import { Icon } from "#ui/icon";
import { H2 } from "#ui/typography";

export function CardMarketIndices(props: CardRootProps) {
  return (
    <Card {...props}>
      <CardHeader>
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-institution" size="baird-md" />
          <span>Market Indices</span>
        </H2>
      </CardHeader>
      <CardContent>TBD</CardContent>
    </Card>
  );
}
