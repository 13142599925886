import { getJson } from "#app/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { queryOptions } from "@tanstack/react-query";

export type FundTransferEligibility = "Market" | "Allow" | "Hide";

export type OnlineTransferRequestEligibility =
  | "Allow"
  | "Unenrolled"
  | "Market"
  | "Hide";

export type AccountEligibility = {
  accountNumber: string;
  canAch: boolean;
  canMobileDeposit: boolean;
  canTransferRequest: boolean;
};

export type AccountEligibilityResponse = {
  mobileDeposit: FundTransferEligibility;
  ach: FundTransferEligibility;
  accounts: AccountEligibility[];
  onlineTransferRequest: OnlineTransferRequestEligibility;
};

const GATEWAY_PATH = "fundtransfer/v1";

export async function getUserEligibility(): Promise<AccountEligibilityResponse> {
  const result = await getJson<AccountEligibilityResponse>(
    `/${GATEWAY_PATH}/User/Eligibility`,
  );
  return result;
}

export const fundTransferUserQueryKeys = createQueryKeys(
  "fundTransferServiceUser",
  {
    detail: {
      queryKey: null,
      queryFn: () => getUserEligibility(),
    },
  },
);

export function useUserEligibility() {
  return queryOptions(fundTransferUserQueryKeys.detail);
}
