import { type IconName } from "./icon.names";

export type AlertRole = "alert" | "status" | "log" | "none";

export type AlertAriaLive = "off" | "polite" | "assertive";

export type AlertAriaAtomic = boolean;

export type AlertIntent =
  | "error"
  | "warning"
  | "success"
  | "info"
  | "help"
  | "loading";

export type AlertPalette = "error" | "warning" | "success" | "info" | "neutral";

export function toAlertAriaLive(role?: AlertRole): AlertAriaLive {
  if (role === "alert") return "assertive";
  if (role === "status" || role === "log") return "polite";
  return "off";
}

export function toAlertAriaAtomic(role?: AlertRole): AlertAriaAtomic {
  if (role === "alert" || role === "status") return true;
  return false;
}

export const alertPaletteMap = {
  error: "error",
  warning: "warning",
  success: "success",
  info: "info",
  help: "neutral",
  loading: "neutral",
} as const satisfies Record<AlertIntent, AlertPalette>;

export function toAlertPalette(intent?: AlertIntent): AlertPalette {
  return alertPaletteMap[intent ?? "help"];
}

export const alertIconNameMap = {
  error: "ms-error-filled",
  warning: "ms-warning-filled",
  success: "ms-check",
  info: "ms-info",
  help: "ms-help",
  loading: "custom-spinner",
} as const satisfies Record<AlertIntent, IconName>;

export type AlertIconName =
  (typeof alertIconNameMap)[keyof typeof alertIconNameMap];

export function toAlertIconName(intent?: AlertIntent): AlertIconName {
  return alertIconNameMap[intent ?? "help"];
}
