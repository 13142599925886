"use client";

import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { allQueryKeys } from "#app/_api/allQueryKeys";
import { transformSettings } from "#app/_api/external-edelivery-service/get-settings";
import {
  SessionStorageKeys,
  useSessionStorage,
} from "#app/lib/useSessionStorage";
import { useQuery } from "@tanstack/react-query";
import {
  AlertDialogPaperless,
  toShowPaperlessDialog,
} from "./AlertDialogPaperless";
import { AlertDialogSharing, toShowSharingDialog } from "./AlertDialogSharing";
import { AlertDialogWelcome, toShowWelcomeDialog } from "./AlertDialogWelcome";

export function AlertDialogs() {
  // Has the user seen a dialog during this session?
  const [hasSeenModal, setHasSeenModal] = useSessionStorage(
    SessionStorageKeys.hasSeenModal,
    false,
  );

  // Only call for additional data if user has NOT seen a dialog
  const resultMetaData = useQuery({
    ...allQueryKeys.externalUserProfileService.metaData,
    enabled: !hasSeenModal,
  });
  const resultSharingInvites = useQuery({
    ...allQueryKeys.externalBolAccountService.sharingInvites,
    enabled: !hasSeenModal,
  });
  const resultPaperlessSettings = useQuery({
    ...allQueryKeys.externalEDeliveryService.settings({
      accountIndexes: getCurrentJwt().getAllAccountIndex(),
    }),
    select: transformSettings,
    enabled: !hasSeenModal,
  });
  const resultNotification = useQuery({
    ...allQueryKeys.externalBolNotificationService.notification(),
    enabled: !hasSeenModal,
  });

  // User has seen a modal during this session, do NOT render ANY dialogs
  if (hasSeenModal) {
    return null;
  }

  // 1st possible dialog to show: Welcome Splash
  const showWelcomeDialog = resultMetaData.isSuccess
    ? toShowWelcomeDialog({
        metaData: resultMetaData.data,
      })
    : false;
  if (showWelcomeDialog) {
    return (
      <AlertDialogWelcome
        userType={showWelcomeDialog}
        onClose={() => setHasSeenModal(true)}
      />
    );
  }

  // 2nd possible dialog to show: Account Sharing
  const showSharingDialog = resultSharingInvites.isSuccess
    ? toShowSharingDialog({
        sharingInvitesData: resultSharingInvites.data,
      })
    : false;
  if (showSharingDialog) {
    return <AlertDialogSharing onClose={() => setHasSeenModal(true)} />;
  }

  // 3rd possible dialog to show: Paperless Settings
  const showPaperlessDialog =
    resultMetaData.isSuccess &&
    resultPaperlessSettings.isSuccess &&
    resultNotification.isSuccess
      ? toShowPaperlessDialog({
          metaData: resultMetaData.data,
          paperlessSettingsData: resultPaperlessSettings.data,
          notificationData: resultNotification.data,
        })
      : false;
  if (showPaperlessDialog) {
    return <AlertDialogPaperless onClose={() => setHasSeenModal(true)} />;
  }

  // Do NOT render ANY dialogs
  return null;
}
