"use client";

import { Badge } from "#ui/badge";
import { type CardRootProps, Card, CardContent, CardHeader } from "#ui/card";
import { Autoplay, Carousel, CarouselItem } from "#ui/carousel";
import { Icon } from "#ui/icon";
import { AnchorButton } from "#ui/link";
import { cx } from "#ui/style.utils";
import { H2, P } from "#ui/typography";
import Image from "next/image";

export function CardWhatsNew(props: CardRootProps) {
  return (
    <Card {...props}>
      <CardHeader>
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-star" size="baird-md" />
          <span>What&rsquo;s New</span>
        </H2>
      </CardHeader>
      <CardContent>
        <WhatsNewCarousel />
      </CardContent>
    </Card>
  );
}
export function CardWhatsNewPreclient(props: CardRootProps) {
  return (
    <Card {...props}>
      <CardHeader className="sr-only">
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-star" size="baird-md" />
          <span>What&rsquo;s New</span>
        </H2>
      </CardHeader>
      <CardContent>
        <WhatsNewCarousel />
      </CardContent>
    </Card>
  );
}

function WhatsNewCarousel() {
  return (
    <Carousel
      className={cx(
        // root
        "@container",
        // root container
        "*:rounded-none",
        // buttons
        "[&>*>*:nth-child(2)]:top-[95px] [&>*>*:nth-child(3)]:top-[95px]",
        // indicators
        "last:*:*:bottom-0 last:*:*:right-0",
      )}
      options={{ loop: true }}
      plugins={[Autoplay({ delay: 5000, playOnInit: true })]}
    >
      <CarouselItem className="items-stretch">
        <WhatsNewCarouselContent1 />
      </CarouselItem>
      <CarouselItem className="items-stretch">
        <WhatsNewCarouselContent2 />
      </CarouselItem>
      <CarouselItem className="items-stretch">
        <WhatsNewCarouselContent3 />
      </CarouselItem>
      <CarouselItem className="items-stretch">
        <WhatsNewCarouselContent4 />
      </CarouselItem>
    </Carousel>
  );
}

interface WhatsNewCarouselContentProps
  extends React.ComponentPropsWithoutRef<"div"> {
  imageElement: React.ReactElement<typeof Image>;
}

function WhatsNewCarouselContent({
  className,
  imageElement,
  children,
  ...props
}: WhatsNewCarouselContentProps) {
  return (
    <div
      {...props}
      className={cx(
        "grid w-full max-w-370px gap-20px @2xl:max-w-fit @2xl:grid-cols-2",
        className,
      )}
    >
      <div className="aspect-[2/1] overflow-hidden rounded-lg bg-carousel-gradient-start">
        {imageElement}
      </div>
      <div className="flex flex-col items-start gap-20px @2xl:self-center @2xl:pr-18px">
        {children}
      </div>
    </div>
  );
}

function WhatsNewCarouselContent1(
  props: Omit<WhatsNewCarouselContentProps, "children" | "imageElement">,
) {
  return (
    <WhatsNewCarouselContent
      {...props}
      imageElement={
        <Image
          src="/Images/carousel-slide-1.png"
          alt="Cell phone showing the Baird mobile app"
          width="956"
          height="798"
          priority
          className="mx-auto h-full w-auto"
        />
      }
    >
      <Badge size="sm" variant="solid" palette="info">
        Mobile App
      </Badge>
      <H2 size="h6" className="-mb-12px">
        Download the Baird Online App!
      </H2>
      <P size="sm">
        It&rsquo;s an easy-to-access smartphone application that puts your Baird
        accounts in the palm of your hand.
      </P>
      <div className="space-x-10px">
        <AnchorButton
          href="https://itunes.apple.com/us/app/baird-online/id777691707?mt=8"
          target="_blank"
          rel="noopener noreferrer"
          size="sm"
          variant="outline"
        >
          Apple
        </AnchorButton>
        <AnchorButton
          href="https://play.google.com/store/apps/details?id=com.rwbaird.investorandroid"
          target="_blank"
          rel="noopener noreferrer"
          size="sm"
          variant="outline"
        >
          Android
        </AnchorButton>
      </div>
    </WhatsNewCarouselContent>
  );
}

function WhatsNewCarouselContent2(
  props: Omit<WhatsNewCarouselContentProps, "children" | "imageElement">,
) {
  return (
    <WhatsNewCarouselContent
      {...props}
      imageElement={
        <Image
          src="/Images/carousel-slide-2.png"
          alt="Laptop showing the Baird Online Welcome page"
          width="956"
          height="798"
          className="mx-auto -mt-22px h-240px w-auto"
        />
      }
    >
      <Badge size="sm" variant="solid" palette="info">
        Explore Baird Online
      </Badge>
      <H2 size="h6" className="-mb-12px">
        <span className="inline-block">New to Baird Online?</span>{" "}
        <span className="inline-block">Take a tour to see how it works.</span>
      </H2>
      <P size="sm">
        Access all the features Baird Online has to offer in this digital world.
      </P>
      <div>
        <AnchorButton
          href="https://www.boldemo.rwbaird.com/getstarted"
          target="_blank"
          rel="noopener noreferrer"
          size="sm"
          variant="outline"
        >
          Take a tour
        </AnchorButton>
      </div>
    </WhatsNewCarouselContent>
  );
}

function WhatsNewCarouselContent3(
  props: Omit<WhatsNewCarouselContentProps, "children" | "imageElement">,
) {
  return (
    <WhatsNewCarouselContent
      {...props}
      imageElement={
        <Image
          src="/Images/carousel-slide-3.png"
          alt="Laptop showing the Baird Online My Plan page"
          width="956"
          height="800"
          className="mx-auto -mt-25px h-240px w-auto"
        />
      }
    >
      <Badge size="sm" variant="solid" palette="info">
        Explore 360 Wealth
      </Badge>
      <H2 size="h6" className="-mb-12px">
        View all your finances in one place
      </H2>
      <P size="sm">
        Link a variety of non-Baird accounts to give you a holistic view of your
        financial wellness.
      </P>
      <div>
        <AnchorButton
          href="https://www.boldemo.rwbaird.com/360-wealth"
          target="_blank"
          rel="noopener noreferrer"
          size="sm"
          variant="outline"
        >
          Learn more
        </AnchorButton>
      </div>
    </WhatsNewCarouselContent>
  );
}

function WhatsNewCarouselContent4(
  props: Omit<WhatsNewCarouselContentProps, "children" | "imageElement">,
) {
  return (
    <WhatsNewCarouselContent
      {...props}
      imageElement={
        <Image
          src="/Images/carousel-slide-4.png"
          alt="Laptop showing the Baird Online My Plan page"
          width="956"
          height="800"
          className="mx-auto -mt-25px h-240px w-auto"
        />
      }
    >
      <Badge size="sm" variant="solid" palette="info">
        Financial Planning
      </Badge>
      <H2 size="h6" className="-mb-12px">
        Build a financial plan with your Baird Advisor
      </H2>
      <P size="sm">
        A Baird Financial Advisor can help you prioritize and plan so you can
        achieve your goals of tomorrow without sacrificing what you want for
        yourself today.
      </P>
      <div>
        <AnchorButton
          href="https://www.boldemo.rwbaird.com/planning"
          target="_blank"
          rel="noopener noreferrer"
          size="sm"
          variant="outline"
        >
          Learn more
        </AnchorButton>
      </div>
    </WhatsNewCarouselContent>
  );
}
