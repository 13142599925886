"use client";

import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwtAccounts } from "#app/(unauthorized)/authentication/useJwt";
import {
  type DocumentInfo,
  type DocumentMetaData,
} from "#app/_api/all-documents-api";
import { allQueryKeys } from "#app/_api/allQueryKeys";
import { useQueryFinancialPlansDocumentsAll } from "#app/_api/financial-plans-service";
import { AccountInfo } from "#app/_ui/components/accounts/AccountInfo";
import { ErrorCardContent } from "#app/_ui/components/cards/ErrorCard";
import { NoResultsCardContent } from "#app/_ui/components/cards/NoResultsCard";
import { PendingCardContent } from "#app/_ui/components/cards/PendingCard";
import {
  PdfViewerDialog,
  PdfViewerDialogTrigger,
} from "#app/_ui/components/media/PdfViewerDialog";
import { getBlob } from "#app/lib/fetchClient";
import { logError } from "#app/lib/logger";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  type CardRootProps,
} from "#ui/card";
import { Icon } from "#ui/icon";
import { type IconName } from "#ui/icon.names";
import { LinkButton } from "#ui/link";
import { cx } from "#ui/style.utils";
import { H2, Span } from "#ui/typography";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

type DocumentSource = "statement" | "finplan" | "clientCommunication";

type DateDocument = {
  sourceType: DocumentSource;
  date: Date;
  doc: DocumentInfo;
  docType: DocumentType;
  accountNumber: string;
};

type DocumentMetaDataType = {
  meta: DocumentMetaData;
  type: DocumentSource;
  docType: DocumentType;
};

const typeToText = {
  //received: "From my advisor",
  clientCommunication: "Client Communication",
  finplan: "Financial Plan",
  statement: "Statement",
  //confirmation: "Confirmation",
  //checks: "Check",
  //tax: "Tax Document",
} as const satisfies Record<DocumentSource, string>;

const accountTypeMapping = {
  //received: { iconName: "baird-secure-document", bgColor: "baird-primary-100" },
  //confirmation: { iconName: "baird-confirmation", bgColor: "bg-[#4A7F6C]" },
  clientCommunication: {
    iconName: "baird-client-communication",
    bgColor: "bg-[#AB5232]",
  },
  //checks: { iconName: "baird-check", bgColor: "bg-[#3B909D]" },
  finplan: { iconName: "baird-financial-plan", bgColor: "bg-primary-100" },
  statement: { iconName: "baird-marketing-strategy", bgColor: "bg-[#1A4B82]" },
  //tax: { iconName: "baird-secure-document", bgColor: "bg-black" },
} as const satisfies Record<
  DocumentSource,
  { iconName: IconName; bgColor: string }
>;

const MAX_ACCOUNTS_LENGTH = 17;

export function CardRecentDocuments(props: CardRootProps) {
  const accounts = useJwtAccounts();
  const isPreclient = getCurrentJwt().segment === Segment.Preclient;

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() + 1);

  const yearAgo = new Date();
  yearAgo.setHours(0, 0, 0, 0);
  yearAgo.setFullYear(yearAgo.getFullYear() - 1);

  const params = {
    accounts: accounts,
    beginDate: yearAgo,
    endDate: today,
  };

  //ROUTE: communications/documents
  const cc = useQuery({
    ...allQueryKeys.externalClientsCommunicationsService.clientCommunicationsDocuments(
      {
        beginDate: params.beginDate.toISOString(),
        endDate: params.endDate.toISOString(),
        acctIndex: getCurrentJwt().getAccountIndex(params.accounts),
      },
    ),
    placeholderData: keepPreviousData,
    enabled: !isPreclient,
  });
  //ROUTE: financialplan/documents
  const fp = useQueryFinancialPlansDocumentsAll(
    params.beginDate,
    params.endDate,
  );
  //ROUTE: statements/documents
  const st = useQuery({
    ...allQueryKeys.externalStatementsService.statementsDocuments({
      acctIndex: getCurrentJwt().getAccountIndex(params.accounts),
      beginDate: params.beginDate.toISOString(),
      endDate: params.endDate.toISOString(),
    }),
    placeholderData: keepPreviousData,
    enabled: !!params.accounts && !isPreclient,
  });

  const isPending = [cc, st, fp].some((result) => result.isLoading);
  const isError = [cc, st, fp].some((result) => result.isError);
  const error = [cc, st, fp].some((result) => result.error);
  if (error) logError(error);

  const isSuccess = [cc, fp, st].every((result) => result.isSuccess);

  const allMeta = [
    ...(cc.data?.map((x) => {
      return {
        meta: x,
        type: "clientCommunication",
        docType: x.type,
        accountNumber: x.accountNumber,
      } as unknown as DocumentMetaDataType;
    }) ?? []),
    ...(fp.data?.map((x) => {
      return {
        meta: x,
        type: "finplan",
        docType: x.type,
        accountNumber: x.accountNumber,
      } as DocumentMetaDataType;
    }) ?? []),
    ...(st.data?.map((x) => {
      return {
        meta: x,
        type: "statement",
        docType: x.type,
        accountNumber: x.accountNumber,
      } as unknown as DocumentMetaDataType;
    }) ?? []),
  ];

  const docs = allMeta.flatMap((x) =>
    x.meta.docs.map((y) => {
      return {
        doc: y,
        date: x.meta.date,
        sourceType: x.type,
        docType: x.docType,
        accountNumber: x.meta.accountNumber,
      } as DateDocument;
    }),
  );

  const allDocs = docs.sort((x, y) => (x.date > y.date ? -1 : 1)).slice(0, 5);

  return accounts.length <= MAX_ACCOUNTS_LENGTH ? (
    <div>
      <Card {...props}>
        <CardHeader palette="gray">
          <H2 size="h6" className="flex items-center gap-10px">
            <Icon name="baird-secure-document" size="baird-md" />
            <span>Recent Documents</span>
          </H2>
        </CardHeader>
        {isPending ? <PendingCardContent /> : null}
        {isError ? <ErrorCardContent /> : null}
        {isSuccess && allDocs.length > 0 ? (
          <CardContent className="p-0">
            <ul className="divide-y divide-shade-10">
              {allDocs.map((x) => (
                <li
                  key={x.doc.url}
                  className="relative isolate flex gap-15px px-30px py-20px"
                >
                  <div
                    className={cx(
                      "inline-block h-40px w-40px rounded-md bg-primary-100 p-9px",
                      accountTypeMapping[x.sourceType].bgColor,
                    )}
                  >
                    <Icon
                      name={accountTypeMapping[x.sourceType].iconName}
                      palette="white"
                      size="baird-sm"
                    />
                  </div>
                  <div className="flex-1">
                    <Span className="block" size="sm">
                      {x.doc.desc}
                    </Span>
                    <Span className="block" size="sm" palette="neutral-subtle">
                      {typeToText[x.sourceType]}
                    </Span>
                    <Span
                      className="block"
                      palette="neutral-subtle"
                      size="sm"
                    >{`${format(x.date, "LLL d, yyyy")}`}</Span>
                    {x.accountNumber ? (
                      <AccountInfo
                        accountNumber={x.accountNumber}
                        className="block text-shade-70"
                      />
                    ) : null}
                  </div>
                  <div>
                    <PdfViewerDialog
                      fileName={x.doc.desc}
                      queryFn={() => getBlob(x.doc.url.slice(4))}
                      triggerElement={
                        <PdfViewerDialogTrigger className="before:absolute before:inset-0" />
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          </CardContent>
        ) : !isPending && !isError ? (
          <NoResultsCardContent title="You have no recent documents" />
        ) : null}
        <CardFooter palette="white">
          <LinkButton className="w-full" href="/documents">
            See all
          </LinkButton>
        </CardFooter>
      </Card>
    </div>
  ) : null;
}
