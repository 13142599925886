"use client";

import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { allQueryKeys } from "#app/_api/allQueryKeys";
import { type GetNotificationData } from "#app/_api/external-bol-notification-service/get-notification";
import { type PaperlessVm } from "#app/_api/external-edelivery-service/get-settings";
import { type GetMetaDataData } from "#app/_api/external-user-profile-service/get-metadata";
import { postMetaData } from "#app/_api/external-user-profile-service/post-metadata";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogCloseIconButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogMain,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "#ui/alert-dialog";
import { Button } from "#ui/button";
import { Icon } from "#ui/icon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { differenceInMonths, isAfter, parseISO } from "date-fns";
import { useRouter } from "next/navigation";
import { useState } from "react";

export function AlertDialogPaperless({ onClose }: { onClose: () => void }) {
  const [open, setOpen] = useState(true);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: postMetaData,
    // onSuccess & onError handled individually
    // Always refetch after error or success:
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: allQueryKeys.externalUserProfileService.metaData.queryKey,
      });
    },
  });

  const router = useRouter();

  const createPostMetaDataParams = () => ({
    metaDataTypeName: "PaperlessSettingsModalConfirmDate",
    body: { value: new Date().toISOString() },
  });

  const saveSessionAndRedirect = () => {
    onClose();
    router.push("/profile-and-settings/paperless-settings");
  };

  const saveSessionAndClose = () => {
    onClose();
    setOpen(false);
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent
          variant="center-scroll-inner"
          className="w-full max-w-500px"
        >
          <AlertDialogMain className="p-30px">
            <div className="space-y-20px">
              <AlertDialogTitle className="flex gap-8px font-semibold">
                <span className="flex h-[1.1lh] items-center">
                  <Icon name="ms-leaf" inline size="lg" palette="success" />
                </span>{" "}
                <span>Go paperless. Save time and help the environment.</span>
              </AlertDialogTitle>
              <AlertDialogDescription className="text-pretty font-normal">
                Opt in to go paperless on all your statements and documents.
                It&rsquo;s secure, and you will help the environment by saving
                paper.
              </AlertDialogDescription>
              <div className="space-x-20px">
                <AlertDialogAction asChild>
                  <Button
                    onClick={() => {
                      if (getCurrentJwt().isImpersonated) {
                        saveSessionAndRedirect();
                        return;
                      }
                      mutation.mutate(createPostMetaDataParams(), {
                        onSettled: () => saveSessionAndRedirect(),
                      });
                    }}
                  >
                    Opt-in for paperless
                  </Button>
                </AlertDialogAction>
                <AlertDialogCancel asChild>
                  <Button
                    variant="link"
                    onClick={() => saveSessionAndClose()}
                  >
                    Remind me later
                  </Button>
                </AlertDialogCancel>
              </div>
            </div>
            <AlertDialogCloseIconButton
              onClick={() => {
                if (getCurrentJwt().isImpersonated) {
                  saveSessionAndClose();
                  return;
                }
                mutation.mutate(createPostMetaDataParams(), {
                  onSettled: () => saveSessionAndClose(),
                });
              }}
            />
          </AlertDialogMain>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

/**
 * Checks paperless settings creation, confirmation, and ownership
 */
export function toShowPaperlessDialog({
  metaData,
  notificationData,
  paperlessSettingsData,
}: {
  metaData: GetMetaDataData;
  notificationData: GetNotificationData;
  paperlessSettingsData: PaperlessVm[];
}): boolean {
  const isPaperSet = paperlessSettingsData
    .filter((s) => s.access === "Owner")
    .some(
      (s) =>
        s.clientCom === "Paper" ||
        s.confirms === "Paper" ||
        (s.hasFdr && s.fdr === "Paper") ||
        s.statements === "Paper" ||
        s.tax === "Paper",
    );

  const dialogIso = metaData.find(
    (el) => el.key === "PaperlessSettingsModalConfirmDate",
  )?.value;
  const dialogDate = dialogIso ? parseISO(dialogIso) : null;

  const notificationIso = notificationData
    .filter((el) => el.typeId === 1)
    .toSorted((a, b) => (a.created < b.created ? 1 : 0))
    .at(0)?.created;
  const notificationDate = notificationIso ? parseISO(notificationIso) : null;

  // Has NOT closed dialog at all
  if (isPaperSet && dialogDate === null) {
    return true;
  }

  // Has NOT closed dialog within the last 12 months
  if (
    isPaperSet &&
    dialogDate !== null &&
    differenceInMonths(new Date(), dialogDate) >= 12
  ) {
    return true;
  }

  // Has paperless notification newer than closed dialog date
  if (
    isPaperSet &&
    dialogDate !== null &&
    notificationDate !== null &&
    isAfter(notificationDate, dialogDate)
  ) {
    return true;
  }

  return false;
}
