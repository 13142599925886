"use client";

import {
  truncateAccountNumber,
  useAccountInfoByAccountNumber,
} from "#app/lib/accountUtils";
import { Icon } from "#ui/icon";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "#ui/popover";
import { cx } from "#ui/style.utils";

export interface AccountInfoProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "children"> {
  /** Used as an override in <AccountInfo> */
  accountNickname?: string | null | undefined;
  accountNumber: string | number | null | undefined;
  accountType?: string | null | undefined;
  hasInfoPopover?: boolean;
  variant?: "single" | "double" | "triple";
}

export function AccountInfo({
  accountNickname,
  accountNumber,
  ...props
}: Omit<AccountInfoProps, "accountType">) {
  const result = useAccountInfoByAccountNumber(accountNumber);

  if (!result.isSuccess) {
    return null;
  }

  return (
    <AccountInfoSync
      {...props}
      accountNickname={accountNickname ?? result.data.accountNickname}
      accountNumber={accountNumber}
      accountType={result.data.accountType}
    />
  );
}

export function AccountInfoSync({
  accountNickname,
  accountNumber,
  accountType,
  className,
  hasInfoPopover = false,
  variant = "single",
  ...props
}: AccountInfoProps) {
  if (!accountNumber) {
    return (
      <div {...props} className={cx("text-sm", className)}>
        N/A
      </div>
    );
  }

  if (variant === "triple") {
    return (
      <div {...props} className={className}>
        <div className="text-sm">
          <AccountInfoNickname value={accountNickname} />
        </div>
        <div className="text-xs leading-sm text-shade-70">
          <AccountInfoType value={accountType} />
        </div>
        <div className="text-xs leading-sm text-shade-70">
          <AccountInfoNumber
            value={accountNumber}
            hasInfoPopover={hasInfoPopover}
          />
        </div>
      </div>
    );
  }

  if (variant === "double") {
    return (
      <div {...props} className={className}>
        <div className="text-sm">
          <AccountInfoNickname value={accountNickname} />
        </div>
        <div className="text-xs leading-sm text-shade-70">
          <AccountInfoType value={accountType} />{" "}
          <AccountInfoNumber
            value={accountNumber}
            hasInfoPopover={hasInfoPopover}
          />
        </div>
      </div>
    );
  }

  // variant === "single"
  return (
    <div {...props} className={cx("whitespace-nowrap text-sm", className)}>
      <span>
        <AccountInfoNickname value={accountNickname} />
      </span>{" "}
      <span>
        <AccountInfoNumber
          value={accountNumber}
          hasInfoPopover={hasInfoPopover}
        />
      </span>
    </div>
  );
}

function AccountInfoNickname({
  value,
}: {
  value: AccountInfoProps["accountNickname"];
}) {
  if (!value) {
    return null;
  }
  return (
    <>
      <span className="sr-only">Account Nickname: </span>
      <span>{value}</span>
    </>
  );
}

function AccountInfoType({
  value,
}: {
  value: AccountInfoProps["accountType"];
}) {
  if (!value) {
    return null;
  }
  return (
    <>
      <span className="sr-only">Account Type: </span>
      <span>{value}</span>
    </>
  );
}

function AccountInfoNumber({
  value,
  hasInfoPopover,
}: {
  value: AccountInfoProps["accountNumber"];
  hasInfoPopover: AccountInfoProps["hasInfoPopover"];
}) {
  if (!value) {
    return null;
  }
  const accountNumberTruncated = truncateAccountNumber(value);
  return (
    <>
      <span className="sr-only">Account Number: </span>
      {hasInfoPopover ? (
        <span className="inline-flex w-fit items-center gap-5px">
          <span>{accountNumberTruncated}</span>
          <AccountInfoPopover />
        </span>
      ) : (
        <span>{accountNumberTruncated}</span>
      )}
    </>
  );
}

function AccountInfoPopover() {
  return (
    <Popover>
      <PopoverTrigger>
        <span className="sr-only">Toggle full account number</span>
        <Icon name="ms-info" size="sm" palette="accent" />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent palette="black" size="sm" side="top">
          <div>View full account number in the Statement document</div>
          <PopoverArrow palette="black" />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
}
