"use client";

import { type GetSharingInvitesData } from "#app/_api/external-bol-account-service/get-sharing-invites";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogCloseIconButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogMain,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "#ui/alert-dialog";
import { LinkButton } from "#ui/link";
import Image from "next/image";

export function AlertDialogSharing({ onClose }: { onClose: () => void }) {
  return (
    <AlertDialog
      defaultOpen
      onOpenChange={() => {
        onClose();
      }}
    >
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent
          variant="center-scroll-inner"
          className="w-full max-w-800px" // bg-white/80 backdrop-blur-xl
        >
          <AlertDialogMain className="py-60px">
            <div className="grid gap-20px text-center">
              <div className="pb-10px">
                <Image
                  alt=""
                  src="/Images/share-requests.svg"
                  width="152"
                  height="105"
                  className="mx-auto"
                  priority
                />
              </div>
              <div>
                <AlertDialogTitle className="font-semibold">
                  You have account sharing requests.
                </AlertDialogTitle>
              </div>
              <div>
                <AlertDialogDescription className="text-pretty font-normal text-shade-70">
                  You can review now by clicking the button below. Or you can
                  review later in Profile & Settings.
                </AlertDialogDescription>
              </div>
              <div>
                <AlertDialogAction asChild>
                  <LinkButton href="/profile-and-settings/account-access">
                    View requests
                  </LinkButton>
                </AlertDialogAction>
              </div>
            </div>
            <AlertDialogCancel asChild>
              <AlertDialogCloseIconButton />
            </AlertDialogCancel>
          </AlertDialogMain>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

/**
 * Checks invites received or subscriber accepted
 */
export function toShowSharingDialog({
  sharingInvitesData,
}: {
  sharingInvitesData: GetSharingInvitesData;
}): boolean {
  const hasReceivedInvites = sharingInvitesData.received.length > 0;
  const hasSubscriberAcceptedInvites = sharingInvitesData.sent.some(
    (el) => el.status === "SubscriberAccepted",
  );
  return hasReceivedInvites || hasSubscriberAcceptedInvites;
}
