import { type AccountPerformance, type AccountPeriod, type AnnuityPerformance, type Disclosures, type PortfolioDisclosure, type PortfolioPerformance, type PortfolioPeriod } from "#app/_api/performance-reports-service";

export interface DisclosureVm {
    id: number;
    desc: string;
}

export interface PeriodVm {
    rateOfReturn: number;
    endValue: number;
    startDate: Date;
    endDate: Date;
    disclosures: DisclosureVm[];

}

export interface PerformanceVm {
    accountNumber: string;
    name: string;
    disclaimer: string;
    value: number|null;

    startDate: Date|null;

    mtd: PeriodVm|null;
    ytd: PeriodVm|null;
    oneYear: PeriodVm|null;
    threeYear: PeriodVm|null;
    fiveYear: PeriodVm|null;
    sinceStart: PeriodVm|null;

    color:string;
}


function convertAnnuityToVm(account: AccountPerformance, annuity: AnnuityPerformance) : PerformanceVm {

    return {
        accountNumber: `${annuity.annuityContractNumber}`,
        name: account.accountName,
        disclaimer: "A",
        value: annuity.ytd?.valuationPeriodEndingValue ?? null,
        startDate: annuity?.sinceInception?.valuationPeriodStartDate ?? null,
        
        mtd: convertAccountPeriodVm(annuity?.mtd),
        ytd: convertAccountPeriodVm(annuity?.ytd),
        oneYear: convertAccountPeriodVm(annuity?.oneYear),
        threeYear: convertAccountPeriodVm(annuity?.threeYear),
        fiveYear: convertAccountPeriodVm(annuity?.fiveYear),
        sinceStart: convertAccountPeriodVm(annuity?.sinceInception),
        color:'#aaaaaa'
    }

}



export function breakoutAnnuities(account: AccountPerformance) : PerformanceVm[] {

    const result: PerformanceVm[] = [];
    result.push(convertAccountToVm(account));

    account.annuities.forEach((a) => {
        result.push(convertAnnuityToVm(account, a));
    });

   return result;

}






function convertPortfolioDisclosureVm(disclosure: PortfolioDisclosure[]) : DisclosureVm[] {

    const d = disclosure.map((d) => {return {
        id: d.id,
        desc: d.desc
    }});

    return d;
}

function convertPortfolioPeriodVm(period: PortfolioPeriod|null) : PeriodVm|null {
    
    if (period === null)
        return null;

    return {
        rateOfReturn: period!.percentReturn!,
        endValue: period!.endValue!,
        startDate: period!.startDate!,
        endDate: period!.endDate!,
        disclosures: convertPortfolioDisclosureVm(period.disclosures)
    }

}

export function convertPortfolioToVm(portfolio: PortfolioPerformance) : PerformanceVm {

    return {
        accountNumber: ``,
        name: "Portfolio Performance",
        disclaimer: "",
        value: portfolio.ytd?.endValue ?? null,
        startDate: portfolio?.sinceInception.startDate,
        
        mtd: convertPortfolioPeriodVm(portfolio?.mtd),
        ytd: convertPortfolioPeriodVm(portfolio?.ytd),
        oneYear: convertPortfolioPeriodVm(portfolio?.oneYear),
        threeYear: convertPortfolioPeriodVm(portfolio?.threeYear),
        fiveYear: convertPortfolioPeriodVm(portfolio?.fiveYear),
        sinceStart: convertPortfolioPeriodVm(portfolio?.sinceInception),
        color:'#aaaaaa'
    }
}












function convertAccountDisclosureVm(disclosure: Disclosures[]) : DisclosureVm[] {

    const d = disclosure.map((d) => {return {
        id: d.superscript,
        desc: d.description
    }});

    return d;
}

function convertAccountPeriodVm(period: AccountPeriod|null) : PeriodVm|null {

    if (period === null)
        return null;

    return {
        rateOfReturn: period!.rateOfReturn!,
        endValue: period!.valuationPeriodEndingValue!,
        startDate: period!.valuationPeriodStartDate!,
        endDate: period!.valuationPeriodEndDate!,
        disclosures: convertAccountDisclosureVm(period.disclosureTypeCodes)
    }
}

function convertAccountToVm(account: AccountPerformance) : PerformanceVm {


    return {
        accountNumber: `${account.accountNumber}`,
        name: account.accountName,
        disclaimer: account.isMutualFundLevel4 ? "M" : "",
        value: account.ytd?.valuationPeriodEndingValue ?? null,
        startDate: account?.sinceInception?.valuationPeriodStartDate ?? null,
        
        mtd: convertAccountPeriodVm(account?.mtd),
        ytd: convertAccountPeriodVm(account?.ytd),
        oneYear: convertAccountPeriodVm(account?.oneYear),
        threeYear: convertAccountPeriodVm(account?.threeYear),
        fiveYear: convertAccountPeriodVm(account?.fiveYear),
        sinceStart: convertAccountPeriodVm(account?.sinceInception),
        color:'#aaaaaa'
    }

}