import { cva, type VariantProps } from "./style.utils";

export type GainLossVariants = VariantProps<typeof gainLossVariants>;

export const gainLossVariants = cva({
  base: ["inline-flex items-center gap-2px tabular-nums"],
  variants: {
    palette: {
      neutral: null,
      negative: "text-loss",
      positive: "text-gain",
    },
  },
  defaultVariants: {
    palette: "neutral",
  },
});
