import { Anchor } from "#ui/link";

export function AnchorSecuritySymbol({
  symbol,
  isUnauth = false,
  isFund = false,
}: {
  symbol: string;
  isUnauth?: boolean;
  isFund?: boolean;
}) {
  const base = isUnauth ? "market-tools" : "investing-tools";
  const assetType = isFund ? "mutual-funds" : "stocks";
  return <Anchor href={`/${base}/${assetType}/${symbol}-US`}>{symbol}</Anchor>;
}
