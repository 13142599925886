import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { type NavRoutesConfig } from "#app/_ui/components/headers/nav-routes-config";
import filterHelper from "./filterHelper";
import { type NavRoutesConfigFilterParams } from "./filterNav";

export default function finPlanningNavFilter({
  navRoutesConfig,
  requiredData,
}: NavRoutesConfigFilterParams): NavRoutesConfig {
  const isImpersonated = getCurrentJwt().isImpersonated;
  let filtering = filterHelper(navRoutesConfig);
  if (!requiredData.canUseFinancialPlanning && !isImpersonated) {
    filtering = filtering.removeRouteSubById(
      "ThreeSixtyWealthAndPlanning",
      "PlanningOverview",
    );
  }

  if (!isImpersonated && (!requiredData.canUseFinancialPlanning || requiredData.hasVisiblePlans == false)) {
    filtering = filtering.removeRouteSubById(
      "ThreeSixtyWealthAndPlanning",
      "PlanningMyPlan",
    );
  }

  return filtering.getFilteredRoutesConfig();
}
