"use client";

import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { Segment } from "#app/(unauthorized)/authentication/segment";
import { allQueryKeys } from "#app/_api/allQueryKeys";
import { type GetMetaDataData } from "#app/_api/external-user-profile-service/get-metadata";
import { postMetaData } from "#app/_api/external-user-profile-service/post-metadata";
import { Confetti } from "#app/_ui/components/media/Confetti";
import {
  AlertDialog,
  AlertDialogCloseIconButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogMain,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "#ui/alert-dialog";
import { Button } from "#ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import ReactPlayerVimeo from "react-player/vimeo";

export type AlertDialogWelcomeUserType =
  | "PreClient"
  | "ConvertedClient"
  | "ClientOrPam";

export function AlertDialogWelcome({
  onClose,
  userType,
}: {
  onClose: () => void;
  userType: AlertDialogWelcomeUserType;
}) {
  const [open, setOpen] = useState(true);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: postMetaData,
    // onSuccess & onError handled individually
    // Always refetch after error or success:
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: allQueryKeys.externalUserProfileService.metaData.queryKey,
      });
    },
  });

  const saveSessionAndClose = () => {
    onClose();
    setOpen(false);
  };

  const handleConfirm = () => {
    if (getCurrentJwt().isImpersonated) {
      saveSessionAndClose();
      return;
    }
    mutation.mutate(
      {
        metaDataTypeName:
          userType === "PreClient"
            ? "NewProspectExperienceModalStatus"
            : "NewClientExperienceModalStatus",
        body: { value: "completed" },
      },
      { onSettled: () => saveSessionAndClose() },
    );
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <Confetti
          // Place over <AlertDialogOverlay> by setting the same z-index (50)
          className="z-50"
        />
        <AlertDialogContent
          variant="center-scroll-inner"
          // Place over <Confetti> by setting the z-index higher than <AlertDialogOverlay>
          className="z-[51] w-full max-w-800px"
        >
          <AlertDialogMain className="py-30px sm:p-60px">
            <div className="grid gap-20px text-center">
              <div>
                <AlertDialogTitle className="text-h2">
                  Welcome to Baird Online 2.0!
                </AlertDialogTitle>
              </div>
              <div>
                <AlertDialogDescription className="text-pretty font-normal">
                  Watch this video for a quick demo on how to use the new{" "}
                  {userType === "PreClient" ? "Prospect" : "Client"} Experience.
                </AlertDialogDescription>
              </div>
              <div className="mb-10px mt-20px aspect-video overflow-clip rounded-md bg-shade-10">
                <ReactPlayerVimeo
                  url="https://vimeo.com/468340663"
                  width="100%"
                  height="100%"
                  controls
                />
              </div>
              <div>
                <Button
                  onClick={() => handleConfirm()}
                  pending={mutation.isPending}
                  disabled={mutation.isPending}
                  size="lg"
                >
                  Continue
                </Button>
              </div>
            </div>
            <AlertDialogCloseIconButton onClick={() => handleConfirm()} />
          </AlertDialogMain>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

/**
 * Checks segment, status, and user type
 */
export function toShowWelcomeDialog({
  metaData,
}: {
  metaData: GetMetaDataData;
}): AlertDialogWelcomeUserType | false {
  const { segment } = getCurrentJwt();
  const prospectModalStatus = metaData.find(
    (d) => d.key === "NewProspectExperienceModalStatus",
  )?.value;
  const clientModalStatus = metaData.find(
    (d) => d.key === "NewClientExperienceModalStatus",
  )?.value;

  if (segment === Segment.Preclient && prospectModalStatus === "new") {
    return "PreClient" as const;
  }
  if (
    segment === Segment.IndividualInvestors &&
    prospectModalStatus === "completed" &&
    clientModalStatus === "new"
  ) {
    return "ConvertedClient" as const;
  }
  if (
    (segment === Segment.IndividualInvestors ||
      segment === Segment.PrivateAssetManagement) &&
    clientModalStatus === "new"
  ) {
    return "ClientOrPam" as const;
  }

  return false;
}
