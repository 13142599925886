"use client";

import { AlertDialogs } from "#app/(secured)/(home)/_shared/AlertDialogs";
import { CardMarketIndices } from "#app/(secured)/(home)/_shared/CardMarketIndices";
import { CardMyNetWorth } from "#app/(secured)/(home)/_shared/CardMyNetWorth";
import { CardMyTeam } from "#app/(secured)/(home)/_shared/CardMyTeam";
import { CardMyUpdates } from "#app/(secured)/(home)/_shared/CardMyUpdates";
import { CardQuickActions } from "#app/(secured)/(home)/_shared/CardQuickActions";
import { CardRecentDocuments } from "#app/(secured)/(home)/_shared/CardRecentDocuments";
import { CardWatchlist } from "#app/(secured)/(home)/_shared/CardWatchlist";
import { CardWhatsNewPreclient } from "#app/(secured)/(home)/_shared/CardWhatsNew";
import { Banner } from "#ui/banner";
import { Container } from "#ui/container";
import { Main } from "#ui/main";
import { BannerContentPreclient } from "./_shared/BannerContent";

export function DashboardPreclient() {
  return (
    <Main>
      <Banner>
        <Container>
          <BannerContentPreclient />
        </Container>
      </Banner>
      <Container>
        {/*
            Order of cards differs across breakpoints!
            Using duplicates combined with classes to hide or show.
          */}
        <div className="flex flex-col gap-30px xl:flex-row">
          <div
            id="dashboard-sidebar"
            className="flex flex-col gap-30px xl:basis-1/3"
          >
            <CardMyNetWorth
              id="dashboard-my-net-worth-sidebar"
              className="max-xl:hidden"
            />
            <CardQuickActions id="dashboard-quick-actions-sidebar" />
            <CardMyTeam
              id="dashboard-my-team-sidebar-xl"
              className="max-xl:hidden"
            />
            <CardMyUpdates
              id="dashboard-my-updates-sidebar"
              className="max-xl:hidden"
            />
            <CardWatchlist
              id="dashboard-watchlist-sidebar"
              className="max-xl:hidden"
            />
          </div>
          <div id="dashboard-main" className="flex flex-col gap-30px xl:flex-1">
            <CardWhatsNewPreclient id="dashboard-whats-new-main" />
            <CardMyNetWorth
              id="dashboard-my-net-worth-main"
              className="xl:hidden"
            />
            <CardMyTeam id="dashboard-my-team-main" className="xl:hidden" />
            <CardMyUpdates
              id="dashboard-my-updates-main"
              className="xl:hidden"
            />
            <CardWatchlist
              id="dashboard-watchlist-main"
              className="xl:hidden"
            />
            <CardMarketIndices id="dashboard-market-indices-main" />
            <CardRecentDocuments id="dashboard-recent-documents-main" />
          </div>
        </div>
      </Container>
      <AlertDialogs />
    </Main>
  );
}
