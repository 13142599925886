"use client";

import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { useQueryOptionsGetAccountNicknames } from "#app/_api/user-profile-service";
import { logError } from "#app/lib/logger";
import { useQuery } from "@tanstack/react-query";

export function truncateAccountNumber(value: string | number) {
  return "…".concat(`${value}`.slice(-4));
}

export function useAccountInfo() {
  const jwt = useJwt();

  const isEnabled =
    jwt.requiredActivities.length === 0 &&
    [Segment.IndividualInvestors, Segment.PrivateAssetManagement].includes(
      jwt.segment,
    );

  const sharedQueryOptions = isEnabled
    ? {
        staleTime: Infinity,
        gcTime: 0,
      }
    : {
        staleTime: 0,
      };

  const result = useQuery({
    ...useQueryOptionsGetAccountNicknames(),
    ...sharedQueryOptions,
  });

  if (result.isError) {
    logError(result.error);
  }

  const data =
    isEnabled && result.isSuccess && result.data
      ? result.data.map((el) => ({
          accountNumber: el.accountNumber,
          accountNickname: el.name,
          accountType: el.accountType,
        }))
      : [];

  return { ...result, data };
}

export function useAccountInfoByAccountNumber(
  accountNumber: string | number | null | undefined,
) {
  const result = useAccountInfo();

  const item = result.data.find(
    (el) => `${el.accountNumber}` === `${accountNumber}`,
  );

  const data = {
    accountNickname: item?.accountNickname,
    accountNumber,
    accountType: item?.accountType,
  };

  return { ...result, data };
}
