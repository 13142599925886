"use client";

import { BannerContent } from "#app/(secured)/(home)/_shared/BannerContent";
import { CardAssetAllocation } from "#app/(secured)/(home)/_shared/CardAssetAllocation";
import { CardMarketIndices } from "#app/(secured)/(home)/_shared/CardMarketIndices";
import { CardMarketValueHistory } from "#app/(secured)/(home)/_shared/CardMarketValueHistory";
import { CardMyNetWorth } from "#app/(secured)/(home)/_shared/CardMyNetWorth";
import { CardMyPortfolio } from "#app/(secured)/(home)/_shared/CardMyPortfolio";
import { CardMyTeam } from "#app/(secured)/(home)/_shared/CardMyTeam";
import { CardMyUpdates } from "#app/(secured)/(home)/_shared/CardMyUpdates";
import { CardPlanning } from "#app/(secured)/(home)/_shared/CardPlanning";
import { CardPortfolioPerformance } from "#app/(secured)/(home)/_shared/CardPortfolioPerformance";
import { CardQuickActions } from "#app/(secured)/(home)/_shared/CardQuickActions";
import { CardRecentActivity } from "#app/(secured)/(home)/_shared/CardRecentActivity";
import { CardRecentDocuments } from "#app/(secured)/(home)/_shared/CardRecentDocuments";
import { CardWatchlist } from "#app/(secured)/(home)/_shared/CardWatchlist";
import { CardWhatsNew } from "#app/(secured)/(home)/_shared/CardWhatsNew";
import { Banner } from "#ui/banner";
import { Container } from "#ui/container";
import { Main } from "#ui/main";
import { cx } from "#ui/style.utils";
import { AlertDialogs } from "#app/(secured)/(home)/_shared/AlertDialogs";

export function Dashboard() {  
  return (
    <Main>
      <Banner
        // Custom banner/card overlap
        className={cx(
          "-mb-40px pb-80px pt-50px",
          "md:-mb-40px md:pb-80px md:pt-50px",
          "xl:pb-94px xl:-mb-54px xl:pt-50px",
        )}
      >
        <Container>
          <BannerContent />
        </Container>
      </Banner>
      <Container>
        {/*
            Order of cards differs across breakpoints!
            Using duplicates combined with classes to hide or show.
          */}
        <div className="flex flex-col xl:flex-row xl:gap-30px">
          <div id="dashboard-sidebar" className="space-y-30px xl:basis-1/3">
            <CardPlanning id="dashboard-planning-sidebar" />
            <CardQuickActions id="dashboard-quick-actions-sidebar" />
            <CardMyTeam
              className="max-xl:hidden"
              id="dashboard-my-team-sidebar-xl"
            />
            <CardMarketValueHistory
              className="xl:hidden"
              id="dashboard-market-value-history-sidebar"
            />
            <CardMyPortfolio
              className="xl:hidden"
              id="dashboard-my-portfolio-sidebar"
            />
            <CardAssetAllocation
              className="xl:hidden"
              id="dashboard-asset-allocation-sidebar"
            />
            <CardPortfolioPerformance
              className="xl:hidden"
              id="dashboard-portfolio-performance-sidebar"
            />
            <CardRecentActivity
              className="xl:hidden"
              id="dashboard-recent-activity-sidebar"
            />
            <CardMarketIndices
              className="xl:hidden"
              id="dashboard-market-indices-sidebar"
            />
            <CardMyTeam className="xl:hidden" id="dashboard-my-team-sidebar" />
            <CardWhatsNew id="dashboard-whats-new-sidebar" />
            <CardMyNetWorth id="dashboard-my-net-worth-sidebar" />
            <CardMyUpdates id="dashboard-my-updates-sidebar" />
            <CardWatchlist id="dashboard-watchlist-sidebar" />
            <CardRecentDocuments id="dashboard-recent-documents-sidebar" />
          </div>
          <div id="dashboard-main" className="space-y-30px xl:flex-1">
            <CardMarketValueHistory
              className="max-xl:hidden"
              id="dashboard-market-value-history-main"
            />
            <CardMyPortfolio
              className="max-xl:hidden"
              id="dashboard-my-portfolio-main"
            />
            <CardAssetAllocation
              className="max-xl:hidden"
              id="dashboard-asset-allocation-main"
            />
            <CardPortfolioPerformance
              className="max-xl:hidden"
              id="dashboard-portfolio-performance-main"
            />
            <CardMarketIndices
              className="max-xl:hidden"
              id="dashboard-market-indices-main"
            />
            <CardRecentActivity
              className="max-xl:hidden"
              id="dashboard-recent-activity-main"
            />
          </div>
        </div>
      </Container>
      <AlertDialogs />
    </Main>
  );
}
