import { post, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

// Branded type to preserve unions
type DateUpdatedIso = string & {};

export type PostMetaDataParams = {
  metaDataTypeName: string;
  body: { value: "new" | DateUpdatedIso | "completed" };
};

export async function postMetaData(
  params: PostMetaDataParams & Pick<Options, "signal">,
): Promise<void> {
  // Parse incoming
  const { metaDataTypeName } = parseParams(params);

  // Call endpoint
  await post(
    `${GATEWAY_PATHS.externalUserProfileService}/MetaData/${metaDataTypeName}`,
    { json: params.body, signal: params.signal },
  );
}

function parseParams(params: PostMetaDataParams) {
  const metaDataTypeName = encodeURIComponent(params.metaDataTypeName);
  return { metaDataTypeName };
}
