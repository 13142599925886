import { Segment } from "#app/(unauthorized)/authentication/segment";
import { type IconName } from "#ui/icon.names";
import { type LinkProps } from "next/link";

export type NavRoutesConfigItemSub = {
  id: string;
  iconName?: IconName;
  element: React.ReactElement | string;
  path: LinkProps["href"];
  allowList: Segment[];
};

export type NavRoutesConfigItem = {
  id: string;
  iconName: IconName;
  element: React.ReactElement | string;
  allowList: Segment[];
} & (
  | {
      path: LinkProps["href"];
      paths?: never;
    }
  | {
      path?: never;
      paths: Record<string, NavRoutesConfigItemSub>;
    }
);

export type NavRoutesConfig = Record<string, NavRoutesConfigItem>;

const allowListAll = [
  Segment.CorporateEntities,
  Segment.IndividualInvestors,
  Segment.NotLoggedIn,
  Segment.Preclient,
  Segment.PrivateAssetManagement,
] as const satisfies Segment[];

const allowListAllExceptPreclient = [
  Segment.CorporateEntities,
  Segment.IndividualInvestors,
  Segment.NotLoggedIn,
  Segment.PrivateAssetManagement,
] as const satisfies Segment[];

export const NAV_ROUTES_CONFIG = {
  Home: {
    id: "Home",
    iconName: "ms-home-filled",
    element: "Home",
    path: "/",
    allowList: [
      Segment.IndividualInvestors, 
      Segment.PrivateAssetManagement, 
      Segment.Preclient, // preclient content handled within the page
    ],
  },

  BairdResearchCorporate: {
    id: "BairdResearchCorporate",
    iconName: "ms-home-filled",
    element: "Baird Research",
    path: "/investing-tools/baird-research",
    allowList: [Segment.CorporateEntities],
  },

  Investments: {
    id: "Investments",
    iconName: "ms-pie-chart-filled",
    element: "Investments",
    // No landing page, use content from "/investments/accounts"
    paths: {
      InvestmentsAccounts: {
        id: "InvestmentsAccounts",
        element: "Accounts",
        path: "/investments/accounts",
        allowList: allowListAll,
      },
      InvestmentsHoldings: {
        id: "InvestmentsHoldings",
        element: "Holdings",
        path: "/investments/holdings",
        allowList: allowListAll,
      },
      InvestmentsActivity: {
        id: "InvestmentsActivity",
        element: "Activity",
        path: "/investments/activity",
        allowList: allowListAll,
      },
      InvestmentsPerformance: {
        id: "InvestmentsPerformance",
        element: "Performance",
        path: "/investments/performance",
        allowList: allowListAll,
      },
    },
    allowList: [Segment.IndividualInvestors, Segment.PrivateAssetManagement],
  },

  ThreeSixtyWealthAndPlanning: {
    id: "ThreeSixtyWealthAndPlanning",
    iconName: "ms-track-changes-filled",
    element: "360 Wealth & Planning",
    paths: {
      ThreeSixtyWealthOverview: {
        id: "ThreeSixtyWealthOverview",
        element: "360 Overview",
        path: "/360-wealth",
        allowList: allowListAll,
      },
      PlanningOverview: {
        id: "PlanningOverview",
        element: "Planning",
        path: "/planning",
        allowList: allowListAll,
      },
      PlanningMyPlan: {
        id: "PlanningMyPlan",
        element: "My Plan",
        path: "/planning/my-plan",
        allowList: allowListAll,
      },
      ThreeSixtyWealthAccounts: {
        id: "ThreeSixtyWealthAccounts",
        element: "Accounts",
        path: "/360-wealth/accounts",
        allowList: allowListAll,
      },
      ThreeSixtyWealthTransactions: {
        id: "ThreeSixtyWealthTransactions",
        element: "Transactions",
        path: "/360-wealth/transactions",
        allowList: allowListAll,
      },
      ThreeSixtyWealthSpending: {
        id: "ThreeSixtyWealthSpending",
        element: "Spending",
        path: "/360-wealth/spending",
        allowList: allowListAll,
      },
      ThreeSixtyWealthInvestments: {
        id: "ThreeSixtyWealthInvestments",
        element: "Investments",
        path: "/360-wealth/investments",
        allowList: allowListAll,
      },
      ThreeSixtyWealthSettings: {
        id: "ThreeSixtyWealthSettings",
        element: "360 Wealth Settings",
        path: "/360-wealth/settings",
        allowList: allowListAll,
      },
      ThreeSixtyWealthFaqs: {
        id: "ThreeSixtyWealthFaqs",
        element: "360 Wealth FAQs",
        path: "/360-wealth/faqs",
        allowList: allowListAll,
      },
    },
    allowList: [
      Segment.IndividualInvestors,
      Segment.PrivateAssetManagement,
      Segment.Preclient,
    ],
  },

  InvestingTools: {
    id: "InvestingTools",
    iconName: "ms-bar-chart-4-bars-filled",
    element: "Investing Tools",
    // No landing page, use content from "/investing-tools/us-markets"
    paths: {
      InvestingToolsUsMarkets: {
        id: "InvestingToolsUsMarkets",
        element: "US Markets",
        path: "/investing-tools/us-markets",
        allowList: allowListAll,
      },
      InvestingToolsBairdResearch: {
        id: "InvestingToolsBairdResearch",
        element: "Baird Research",
        path: "/investing-tools/baird-research",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement
        ],
      },
      InvestingToolsMarketBriefing: {
        id: "InvestingToolsMarketBriefing",
        element: "Market Briefing",
        path: "/investing-tools/market-briefing",
        allowList: allowListAll,
      },
      InvestingToolsPortfolioTracker: {
        id: "InvestingToolsPortfolioTracker",
        element: "Portfolio Tracker",
        path: "/investing-tools/portfolio-tracker",
        allowList: allowListAll,
      },
      // InvestingToolsPortfolioTrackerCreate: {
      //   id: "InvestingToolsPortfolioTrackerCreate",
      //   element: "Portfolio Tracker - Create",
      //   path: "/investing-tools/portfolio-tracker-create",
      //   allowList: allowListAll,
      // },
      // InvestingToolsPortfolioTrackerEdit: {
      //   id: "InvestingToolsPortfolioTrackerEdit",
      //   element: "Portfolio Tracker - Edit",
      //   path: "/investing-tools/portfolio-tracker-edit",
      //   allowList: allowListAll,
      // },
      InvestingToolsWatchlist: {
        id: "InvestingToolsWatchlist",
        element: "Watchlist",
        path: "/investing-tools/watchlist",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
          Segment.Preclient,
        ],
      },
      // InvestingToolsStocks: {
      //   id: "InvestingToolsStocks",
      //   element: "Stocks",
      //   path: "/investing-tools/stocks",
      //   allowList: allowListAll,
      // },
      InvestingToolsMutualFunds: {
        id: "InvestingToolsMutualFunds",
        element: "Mutual Funds",
        path: "/investing-tools/mutual-funds",
        allowList: allowListAll,
      },
      InvestingToolsNews: {
        id: "InvestingToolsNews",
        element: "News",
        path: "/investing-tools/news",
        allowList: allowListAll,
      },
      InvestingToolsAlerts: {
        id: "InvestingToolsAlerts",
        element: "Alerts",
        path: "/investing-tools/alerts",
        allowList: [Segment.CorporateEntities, Segment.Preclient],
      },
      // InvestingToolsAlertsOverview: {
      //   id: "InvestingToolsAlertsOverview",
      //   element: "Alerts - History",
      //   path: "/investing-tools/alerts",
      //   allowList: allowListAll,
      // },
      // InvestingToolsAlertsList: {
      //   id: "InvestingToolsAlertsList",
      //   element: "Alerts - My Alerts",
      //   path: "/investing-tools/alerts-list",
      //   allowList: allowListAll,
      // },
      // InvestingToolsAlertsCreate: {
      //   id: "InvestingToolsAlertsCreate",
      //   element: "Alerts - Create",
      //   path: "/investing-tools/alerts-create",
      //   allowList: allowListAll,
      // },
      // InvestingToolsAlertsEdit: {
      //   id: "InvestingToolsAlertsEdit",
      //   element: "Alerts - Edit",
      //   path: "/investing-tools/alerts-delivery",
      //   allowList: allowListAll,
      // },
    },
    allowList: allowListAll,
  },

  AccountServices: {
    id: "AccountServices",
    iconName: "ms-how-to-reg-filled",
    element: "Account Services",
    // No landing page, use content from "/account-services/ebill"
    paths: {
      AccountServicesOpenAccount: {
        id: "AccountServicesOpenAccount",
        element: "Open Account",
        path: "/account-services/open-account",
        allowList: [Segment.Preclient],
      },
      AccountServicesEbill: {
        id: "AccountServicesEbill",
        element: "eBill",
        path: "/account-services/ebill",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
        ],
      },
      AccountServicesTransfers: {
        id: "AccountServicesTransfers",
        element: "Transfers",
        path: "/account-services/transfers/transfer-form",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
          Segment.Preclient
        ],
      },
      AccountServicesAwards: {
        id: "AccountServicesAwards",
        element: "Awards",
        path: "/account-services/awards",
        allowList: [Segment.IndividualInvestors, Segment.Preclient],
      },
    },
    allowList: [
      Segment.IndividualInvestors,
      Segment.Preclient,
      Segment.PrivateAssetManagement,
    ],
  },

  Documents: {
    id: "Documents",
    iconName: "ms-description-filled",
    element: "Documents",
    paths: {
      DocumentsOverview: {
        id: "DocumentsOverview",
        element: "All Documents",
        path: "/documents",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
          Segment.Preclient
        ],
      },
      DocumentsReceived: {
        id: "DocumentsReceived",
        element: "Document Exchange",
        path: "/documents/received",
        allowList: allowListAll,
      },
      // DocumentsSent: {
      //   id: "DocumentsSent",
      //   element: "Document Exchange - Sent",
      //   path: "/documents/sent",
      //   allowList: allowListAll,
      // },
      DocumentsStatements: {
        id: "DocumentsStatements",
        element: "Statements",
        path: "/documents/statements",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
          Segment.Preclient
        ],
      },
      DocumentsTaxForms: {
        id: "DocumentsTaxForms",
        element: "Tax Forms",
        path: "/documents/tax-forms",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
          Segment.Preclient
        ],
      },
      DocumentsConfirmations: {
        id: "DocumentsConfirmations",
        element: "Confirmations",
        path: "/documents/confirmations",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
          Segment.Preclient
        ],
      },
      DocumentsClientCommunications: {
        id: "DocumentsClientCommunications",
        element: "Client Communications",
        path: "/documents/client-communications",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
          Segment.Preclient
        ],
      },
      DocumentsChecks: {
        id: "DocumentsChecks",
        element: "Checks",
        path: "/documents/checks",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
          Segment.Preclient
        ],
      },
      DocumentsFinancialPlans: {
        id: "DocumentsFinancialPlans",
        element: "Financial Plans",
        path: "/documents/financial-plans",
        allowList: allowListAll,
      },
      DocumentsPerformance: {
        id: "DocumentsPerformance",
        element: "Performance",
        path: "/documents/performance",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
          Segment.Preclient
        ],
      },
    },
    allowList: [
      Segment.IndividualInvestors,
      Segment.PrivateAssetManagement,
      Segment.Preclient,
    ],
  },

  BairdTrust: {
    id: "BairdTrust",
    iconName: "custom-timeline",
    element: "Baird Trust",
    paths: {
      BairdTrustOverview: {
        id: "BairdTrustOverview",
        element: "Dashboard",
        path: "/baird-trust",
        allowList: allowListAll,
      },
      BairdTrustGainsAndLosses: {
        id: "BairdTrustGainsAndLosses",
        element: "Gains & Losses",
        path: "/baird-trust/gains-and-losses",
        allowList: allowListAll,
      },
      BairdTrustPortfolioDiversification: {
        id: "BairdTrustPortfolioDiversification",
        element: "Portfolio Diversification",
        path: "/baird-trust/portfolio-diversification",
        allowList: allowListAll,
      },
      BairdTrustAccounts: {
        id: "BairdTrustAccounts",
        element: "Accounts",
        path: "/baird-trust/accounts",
        allowList: allowListAll,
      },
      BairdTrustHoldings: {
        id: "BairdTrustHoldings",
        element: "Holdings",
        path: "/baird-trust/holdings",
        allowList: allowListAll,
      },
    },
    allowList: [
      Segment.IndividualInvestors,
      Segment.PrivateAssetManagement,
      Segment.Preclient,
    ],
  },

  ProfileAndSettings: {
    id: "ProfileAndSettings",
    iconName: "ms-person-filled",
    element: "Profile & Settings",
    paths: {
      ProfileAndSettingsAccountAccess: {
        id: "ProfileAndSettingsAccountAccess",
        element: "Account Access",
        path: "/profile-and-settings/account-access",
        allowList: [
          Segment.IndividualInvestors,
          Segment.PrivateAssetManagement,
        ],
      },
      ProfileAndSettingsAccountGroups: {
        id: "ProfileAndSettingsAccountGroups",
        element: "Account Groups",
        path: "/profile-and-settings/account-groups",
        allowList: allowListAllExceptPreclient,
      },
      ProfileAndSettingsAccountNicknames: {
        id: "ProfileAndSettingsAccountNicknames",
        element: "Account Nicknames",
        path: "/profile-and-settings/account-nicknames",
        allowList: allowListAllExceptPreclient,
      },
      ProfileAndSettingsPersonalInformation: {
        id: "ProfileAndSettingsPersonalInformation",
        element: "Personal Information",
        path: "/profile-and-settings/personal-information",
        allowList: allowListAll,
      },
      ProfileAndSettingsUserIdAndPassword: {
        id: "ProfileAndSettingsUserIdAndPassword",
        element: "User ID & Password",
        path: "/profile-and-settings/user-id-and-password",
        allowList: allowListAll,
      },
      ProfileAndSettingsMailingAddresses: {
        id: "ProfileAndSettingsMailingAddresses",
        element: "Mailing Addresses",
        path: "/profile-and-settings/mailing-addresses",
        allowList: allowListAllExceptPreclient,
      },
      ProfileAndSettingsPaperlessSettings: {
        id: "ProfileAndSettingsPaperlessSettings",
        element: "Paperless Settings",
        path: "/profile-and-settings/paperless-settings",
        allowList: allowListAllExceptPreclient,
      },
    },
    allowList: allowListAll,
  },

  // ContactUsUnauthenticated: {
  //   id: "ContactUsUnauthenticated",
  //   iconName: "custom-not-applicable",
  //   element: "Contact Us & FAQs",
  //   path: "/tech-support",
  //   allowList: allowListAll,
  // },
  // ContactUsAuthenticated: {
  //   id: "ContactUsAuthenticated",
  //   iconName: "custom-not-applicable",
  //   element: "Contact Us & FAQs",
  //   path: "/contact-us",
  //   allowList: allowListAll,
  // },

  RegisterClient: {
    id: "RegisterClient",
    iconName: "custom-not-applicable",
    element: "Registration",
    path: "/register",
    allowList: allowListAll,
  },
  RegisterProspect: {
    id: "RegisterProspect",
    iconName: "custom-not-applicable",
    element: "Registration",
    path: "/register/prospect",
    allowList: [Segment.Preclient],
  },

  // ForgotUserId: {
  //   id: "ForgotUserId",
  //   iconName: "custom-not-applicable",
  //   element: "Forgot User ID",
  //   path: "/forgot/user",
  //   allowList: allowListAll,
  // },
  // ForgotPassword: {
  //   id: "ForgotPassword",
  //   iconName: "custom-not-applicable",
  //   element: "Forgot Password",
  //   path: "/forgot/password",
  //   allowList: allowListAll,
  // },
  // ForgotChangePassword: {
  //   id: "ForgotChangePassword",
  //   iconName: "custom-not-applicable",
  //   element: "Change Password",
  //   path: "/forgot/change-password",
  //   allowList: allowListAll,
  // },

  MyTeam: {
    id: "MyTeam",
    iconName: "custom-not-applicable",
    element: "My Team",
    path: "/my-team",
    allowList: allowListAll,
  },

  SignIn: {
    id: "SignIn",
    iconName: "custom-not-applicable",
    element: "Sign In",
    path: "/sign-in",
    allowList: allowListAll,
  },
  SignOut: {
    id: "SignOut",
    iconName: "custom-not-applicable",
    element: "Sign Out",
    path: "/sign-out",
    allowList: allowListAll,
  },
} as const satisfies NavRoutesConfig;

export const NAV_MARKETING_ROUTES_CONFIG = {
  MarketUSMarkets: {
    id: "USMarkets",
    iconName: "ms-home-filled",
    element: "U.S. Markets",
    path: "/market-tools/us-markets",
    allowList: allowListAll,
  },
  MarketBriefing: {
    id: "MarketBriefing",
    iconName: "ms-home-filled",
    element: "Market Briefing",
    path: "/market-tools/market-briefing",
    allowList: allowListAll,
  },
  MarketMutualFunds: {
    id: "MutualFunds",
    iconName: "ms-home-filled",
    element: "Mutual Funds",
    path: "/market-tools/mutual-funds",
    allowList: allowListAll,
  },
  MarketNews: {
    id: "News",
    iconName: "ms-home-filled",
    element: "News",
    path: "/market-tools/news",
    allowList: allowListAll,
  },
} as const satisfies NavRoutesConfig;
